<template>
  <div v-if="shops">
    <CRow>
      <CCol md="12">
        <!-- <CRow>
          <CCol md="10"> </CCol>
          <CCol md="2" class="text-right">
            <router-link to="/member">
              <CButton>
                <CIcon name="cil-arrow-thick-from-right" />
                ย้อนกลับ
              </CButton>
            </router-link>
          </CCol>
        </CRow> -->

        <CCard class="shadow-sm">
          <CCardBody>
            <CAlert v-if="successAlert !== ''" color="success" :show="showtime">
              {{ successAlert }}
            </CAlert>
            <p class="text-danger font-weight-normal text-left" v-if="validateAlert !== ''">
              {{ validateAlert }}
            </p>
            <p class="text-danger font-weight-normal text-left" v-if="errors !== ''">
              {{ errors }}
            </p>
            <CRow>
              <CCol md="4">
                <CCardBody class="tex-center">
                  <div class="circle">
                    <h3>{{ nameIcon(name) }}</h3>
                  </div>
                </CCardBody>
              </CCol>
              <CCol md="8">
                <CCardBody>
                  <CRow>
                    <CCol md="8">
                      <h2 class="font-weight-normal text-left">
                        <img alt="" v-if="isMember === 'line'" :src="imgSrc" width="4%" class="img-fluid" />
                        {{ name }}
                        <CButton color="primary" v-on:click="exportMemberPdf()" variant="outline" square size="sm">
                          <em class="fa fa-print"></em>
                        </CButton>
                      </h2>
                    </CCol>
                    <CCol md="4" class="text-right">
                      <router-link to="/member">
                        <CButton>
                          <CIcon name="cil-arrow-thick-from-right" />
                          {{ $t('back') }}
                        </CButton>
                      </router-link>
                    </CCol>
                  </CRow>
                  <hr />
                  <div v-if="edit === false">
                    <CRow>
                      <CCol md="6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label text-left text-black">
                            {{ $t('fullName') }}
                            <label class="text-danger">*</label>
                          </label>
                          <div class="col-sm-8">
                            <input class="form-control text-black" v-model="name" />
                          </div>
                        </div>
                      </CCol>
                      <CCol md="6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label text-right text-black">
                            {{ $t('dateOfBirth') }}
                          </label>
                          <div class="col-sm-8">
                            <VueDatePicker class=" datepicker-container" color="#29B46B" placeholder="Choose date"
                              :locale="getDateLocale()" :visible-years-number="80" v-model="dateOfBirth"
                              format="DD-MM-YYYY" />
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label text-left text-black">
                            {{ $t('taxID') }}
                          </label>
                          <div class="col-sm-10">
                            <input class="form-control text-black" v-model="taxID" />
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label text-left text-black">
                            {{ $t('address') }} <label class="text-danger">*</label>
                          </label>
                          <div class="col-sm-10">
                            <textarea class="form-control text-black" v-model="address" />
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label text-left text-black">
                            {{ $t('postalCode') }}
                          </label>
                          <div class="col-sm-10">
                            <input class="form-control text-black" v-model="postalCode" />
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label text-left text-black">
                            {{ $t('tel') }}
                            <label class="text-danger">*</label>
                          </label>
                          <div class="col-sm-8">
                            <input class="form-control text-black" v-model="phone" />
                          </div>
                        </div>
                      </CCol>
                      <CCol md="6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label text-right text-black">
                            {{ $t('email') }}
                          </label>
                          <div class="col-sm-8">
                            <input class="form-control text-black" v-model="email" />
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                  </div>
                  <div v-else-if="edit === true">
                    <CRow>
                      <CCol md="6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label text-left text-black">
                            {{ $t('fullName') }}
                            <label class="text-danger">*</label>
                          </label>
                          <div class="col-sm-8">
                            <input class="form-control text-black" v-model="name" disabled />
                          </div>
                        </div>
                      </CCol>
                      <CCol md="6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label text-right text-black">
                            {{ $t('dateOfBirth') }}
                          </label>
                          <div class="col-sm-8">
                            <input v-if="dateOfBirth !== undefined" class="form-control text-black"
                              v-model="dateOfBirth" disabled type="date" />
                            <input v-else class="form-control" disabled />
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label text-left text-black">
                            {{ $t('taxID') }}
                          </label>
                          <div class="col-sm-10">
                            <input class="form-control text-black" v-model="taxID" disabled />
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label text-left text-black">
                            {{ $t('address') }} <label class="text-danger">*</label>
                          </label>
                          <div class="col-sm-10">
                            <textarea class="form-control text-black" v-model="address" disabled />
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label text-left text-black">
                            {{ $t('postalCode') }}
                          </label>
                          <div class="col-sm-10">
                            <input class="form-control text-black" v-model="postalCode" disabled />
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label text-left text-black">
                            {{ $t('tel') }}
                            <label class="text-danger">*</label>
                          </label>
                          <div class="col-sm-8">
                            <input class="form-control text-black" v-model="phone" disabled />
                          </div>
                        </div>
                      </CCol>
                      <CCol md="6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label text-right text-black">
                            {{ $t('email') }}
                          </label>
                          <div class="col-sm-8">
                            <input class="form-control text-black" v-model="email" disabled />
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                  </div>

                  <div v-if="deletedAt === null || deletedAt === undefined">
                    <hr />
                    <CRow v-if="edit === false">
                      <CCol md="12">
                        <CButton block color="success" class="px-4 text-white" @click="validate()">
                          {{ $t('save') }}
                        </CButton>
                      </CCol>
                    </CRow>
                    <CRow v-else-if="edit === true">
                      <CCol md="6">
                        <CButton block color="info" class="px-4 text-white" @click="edit = false">
                          {{ $t('setupInformation') }}
                        </CButton>
                      </CCol>
                      <CCol md="6">
                        <CButton block color="danger" class="px-4 text-white" @click="deleteModal = true">
                          {{ $t('deleteMember') }}
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                </CCardBody>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard class="shadow-sm">
          <CCardBody>
            <CCallout color="success">
              <h5 class="text-muted">{{ $t('member.loyaltyPoint') }}</h5>
              <div class="row">
                <strong class="col-md-10 col-sm-9 h4 font-weight-normal text-success">
                  {{ convertPoint(point) }}
                </strong>
                <div class="col-md-2 col-sm-3 text-right text-success">
                  <CIcon size="2xl" name="cil-gift" />
                </div>
              </div>
            </CCallout>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol>
        <CCard class="shadow-sm">
          <CCardBody>
            <CCallout color="info">
              <h5 class="text-muted">{{ $t('totaValue') }}</h5>
              <div class="row">
                <strong class="col-md-10 h4 font-weight-normal text-info">
                  {{ convertCurrency(grandTotal) }}
                </strong>
                <div class="col-md-2 text-right text-info">
                  <CIcon size="2xl" name="cil-money" />
                </div>
              </div>
            </CCallout>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol>
        <CCard class="shadow-sm">
          <CCardBody>
            <CCallout color="danger">
              <h5 class="text-muted">{{ $t('bill') }}</h5>
              <div class="row">
                <strong class="col-md-10 h4 font-weight-normal text-danger">
                  {{ bill.toString() }}
                </strong>
                <div class="col-md-2 text-right text-danger">
                  <CIcon size="2xl" name="cil-file" />
                </div>
              </div>
            </CCallout>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow v-if="frequencyPurchase.length !== 0">
      <CCol md="12">
        <CCard class="shadow-sm">
          <CCardBody>
            <h5 class="text-left font-weight-normal">
              {{ $t('frequently') }}
            </h5>
            <table class="table" aria-describedby="">
              <thead>
                <th scope="col" class="font-weight-normal">#</th>
                <th scope="col" class="font-weight-normal"></th>
                <th scope="col" class="font-weight-normal">{{ $t('pluCode') }}</th>
                <th scope="col" class="font-weight-normal">{{ $t('productName') }}</th>
                <th class="text-right font-weight-normal" scope="col">{{ $t('quantity') }}</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in frequencyPurchase" :key="index">
                  <td style="width:4%" class="text-left">
                    {{ index + 1 }}
                  </td>
                  <td style="width:6%">
                    <img alt="" :src="item.remoteImagePath" class="img-fluid" style="border-radius: 10%;" width="100%"
                      @error="noImgUrl" />
                  </td>
                  <td class="text-left" style="width:15%">
                    {{ item.pluCode }}
                  </td>
                  <td class="text-left">
                    {{ item.pluName }} ({{ item.unit }})
                  </td>
                  <td class="text-right font-weight-normal">
                    {{ item.quantity }}
                  </td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CCol md="12">
        <CCard class="shadow-sm">
          <CCardBody>
            <h5 class="text-left font-weight-normal">{{ $t('recentPurchase') }}</h5>
            <CDataTable :items="items" :fields="fields" hover border clickableRows @row-clicked="toggleDetails">
              <template #details="{ item, index }">
                <CCollapse :show="details.includes(item)">
                  <CCardBody>
                    <p class="text-left">{{ $t('paymentDescription') }}</p>
                    <CDataTable :items="itemsDetail(index)" :fields="fieldsDetail" hover border>
                    </CDataTable>
                  </CCardBody>
                </CCollapse>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CModal :show.sync="popupModal" color="success" :title="$t('confirmUpdateUserInfo')" :footer="footer" centered>
        <h5 class="text-center">
          <strong>{{ $t('confirmSaveData') }}</strong>
        </h5>
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="4">
              <CButton color="success" block @click="updateMember()" v-if="loadingButton === false">
                {{ $t('confirm') }}
              </CButton>
              <CButton color="success" block v-else-if="loadingButton === true" disabled>
                <CSpinner color="white" size="sm" />
              </CButton>
            </CCol>
            <CCol col="4">
              <CButton color="dark" @click="popupModal = false" block>
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
      <CModal :show.sync="deleteModal" color="danger" :title="this.$i18n.t('deleteMember')" :footer="footer" centered>
        <h5 class="text-center">
          <strong>{{ $t('dialogDeleteMember') }} {{ name }} {{ $t('yesOrNo') }} ?</strong>
        </h5>
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="4">
              <CButton color="danger" block @click="deleteMember()" v-if="loadingButton === false">
                {{ $t('confirm') }}
              </CButton>
              <CButton color="danger" block v-else-if="loadingButton === true" disabled>
                <CSpinner color="white" size="sm" />
              </CButton>
            </CCol>
            <CCol col="4">
              <CButton color="dark" @click="deleteModal = false" block>
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/pos'
import report from '@/services/report'
import moment from 'moment'
import util from '@/util/util'
import NoShop from '@/containers/NoShop.vue'

export default {
  components: { NoShop },
  data() {
    return {
      name: '',
      taxID: '',
      address: '',
      postalCode: '',
      phone: '',
      email: '',
      colorCode: '',
      popupModal: false,
      deleteModal: false,
      footer: '',
      validateAlert: '',
      showtime: 3,
      loadingButton: false,
      successAlert: '',
      edit: '',
      deletedAt: null,
      frequencyPurchase: [],
      lastPurchase: [],
      grandTotal: 0,
      bill: 0,
      details: [],
      value: 100,
      errors: '',
      imgSrc: '../../../img/line-icon.png',
      isMember: '',
      point: 0,
      dateOfBirth: '',
      custom: {
        name: 'th',
        weekdays: 'อาทิตย์_จันทร์_อังคาร_พุธ_พฤหัสบดี_ศุกร์_เสาร์'.split('_'),
        weekdaysShort: 'อา._จ._อ._พ._พฤ._ศ._ส.'.split('_'),
        weekStart: 1,
        months: 'มกราคม_กุมภาพันธ์_มีนาคม_เมษายน_พฤษภาคม_มิถุนายน_กรกฎาคม_สิงหาคม_กันยายน_ตุลาคม_พฤศจิกายน_ธันวาคม'.split('_'),
        monthsShort: 'ม.ค._ก.พ._มี.ค._เม.ย._พ.ค._มิ.ย._ก.ค._ส.ค._ก.ย._ต.ค._พ.ย._ธ.ค.'.split('_'),
        formats: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD/MM/YYYY',
          LL: 'D MMMM YYYY',
          LLL: 'D MMMM YYYY HH:mm',
          LLLL: 'dddd D MMMM YYYY HH:mm',
        },
      }

    }
  },
  computed: {
    ...mapGetters(['shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    objectId() {
      return this.$route.params.objectId
    },
    fields() {
      return [
        { key: 'dateTime', label: 'วันที่', _classes: 'text-left font-weight-normal text-dark' },
        { key: 'receiptNumber', label: 'เลขที่ใบกำกับ', _classes: 'text-left font-weight-normal text-dark' },
        {
          key: 'grandTotal',
          label: this.$i18n.t('amountMoney'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'netTotalIncVAT',
          label: this.$i18n.t('netTotalIncVAT'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'netTotalNonVAT',
          label: this.$i18n.t('netTotalNonVAT'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'netAmountVATTotal',
          label: this.$i18n.t('netAmountVATTotal'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'vatAmount',
          label: this.$i18n.t('vatAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
    items() {
      let purchase = this.lastPurchase
      let info = []
      let grandTotal = '-'
      let netAmountVATTotal = '-'
      let netTotalIncVAT = '-'
      let netTotalNonVAT = '-'
      let vatAmount = '-'
      let classes = 'text-black'

      for (let data of purchase) {
        try {
          if (data.grandTotal !== null) {
            grandTotal = util.convertCurrency(data.grandTotal)
          }

          if (data.netAmountVATTotal !== null) {
            netAmountVATTotal = util.convertCurrency(data.netAmountVATTotal)
          }

          if (data.netTotalIncVAT !== null) {
            netTotalIncVAT = util.convertCurrency(data.netTotalIncVAT)
          }

          if (data.netTotalNonVAT !== null) {
            netTotalNonVAT = util.convertCurrency(data.netTotalNonVAT)
          }

          if (data.vatAmount !== null) {
            vatAmount = util.convertCurrency(data.vatAmount)
          }

          if (data.receiptStatus === 'V') {
            classes = 'text-danger'
          }

          info.push({
            dateTime: moment(String(data.created_at)).format(
              'DD/MM/YYYY HH:mm A'
            ),
            receiptNumber: data.receiptNumber,
            grandTotal: grandTotal,
            receiptItems: data.receiptItems,
            netTotalIncVAT: netTotalIncVAT,
            netTotalNonVAT: netTotalNonVAT,
            netAmountVATTotal: netAmountVATTotal,
            vatAmount: vatAmount,
            receiptStatus: data.receiptStatus,
            _classes: classes,
            receiptObjectId: data.objectId,
          })
        } catch (e) {
          console.log(e)
        }
      }
      return info
    },
    fieldsDetail() {
      return [
        { key: 'pluCode', label: this.$i18n.t('pluCode'), _classes: 'text-left font-weight-normal text-dark' },
        { key: 'pluName', label: this.$i18n.t('productName'), _classes: 'text-left font-weight-normal text-dark' },
        {
          key: 'quantitySKU',
          label: this.$i18n.t('quantity'),
          _classes: 'text-center font-weight-normal text-dark',
        },
        {
          key: 'price',
          label: this.$i18n.t('priceUnit'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'discountTotal',
          label: this.$i18n.t('discount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'totalAmount',
          label: this.$i18n.t('totalAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
  },
  created() {
    this.getMemberByObjectId()
    this.getMemberDashboard()
  },
  methods: {
    ...util,
    getDateLocale() {
      if (this.$i18n.locale == 'en') {
        return { lang: 'en' }
      } else {
        return { lang: this.custom }
      }
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    nameIcon(string) {
      return string.charAt(0).toUpperCase()
    },
    convertPoint(number) {
      if (number !== undefined) {
        return util.convertNumber(number)
      } else {
        return 0
      }
    },
    convertCurrency(number) {
      return util.convertCurrency(number)
    },
    showDate(createdAt) {
      return moment(createdAt).format('DD/MM/YYYY HH:mm')
    },
    validate() {
      if (this.name === '' || this.phone === '' || this.address === '') {
        this.validateAlert = this.$i18n.t('validateInfomation')
      } else {
        this.validateAlert = ''
        if (!this.validatePhone(this.phone)) {
          this.errors = this.$i18n.t('validateTel')
        } else {
          if (this.email !== '') {
            if (!this.validEmail(this.email)) {
              this.errors = this.$i18n.t('validateEmail')
            } else {
              this.validateAlert = ''
              this.popupModal = true
              this.errors = ''
            }
          } else if (this.email === '') {
            this.validateAlert = ''
            this.popupModal = true
            this.errors = ''
          }
        }
      }
    },
    getMemberByObjectId() {
      const uid = this.uid
      const objectId = this.objectId

      let url =
        '/api/v1.0/' + uid + '/Shop/getmemberbyobjectId/' + objectId + '/data'

      pos({
        method: 'get',
        url: url,
      })
        .then((res) => {
          let documents = res.data.data.documents

          if (documents !== null && documents !== undefined) {
            this.edit = true
            this.name = documents.name || documents.firstname + " " + documents.lastname
            this.taxID = documents.taxID
            this.address = documents.address
            this.postalCode = documents.postalCode
            this.phone = documents.phone
            this.email = documents.email
            this.deletedAt = documents.deletedAt
            this.isMember = documents.isMember
            this.point = documents.point
            this.dateOfBirth = documents.dateOfBirth
          } else {
            this.edit = false
            console.log('error')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    updateMember() {
      this.loadingButton = true
      const uid = this.uid

      let data = {
        name: this.name,
        taxID: this.taxID,
        address: this.address,
        postalCode: this.postalCode,
        phone: this.phone,
        email: this.email,
        shopObjectId: this.shopObjectId,
        objectId: this.objectId,
        dateOfBirth: this.dateOfBirth
      }

      if (data.name === '' || data.phone === '' || data.address === '') {
        this.popupModal = false
      } else {
        pos({
          method: 'post',
          url: '/api/v1.0/' + uid + '/Shop/updatemember/data',
          data: data,
        })
          .then((res) => {
            this.loadingButton = false
            this.popupModal = false
            this.successAlert = this.$i18n.t('saveSuccess')
            this.edit = true
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    deleteMember() {
      this.loadingButton = true
      const uid = this.uid
      let data = {
        objectId: this.objectId,
        shopObjectId: this.shopObjectId,
      }

      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/Member/softdelete',
        data: data,
      })
        .then((res) => {
          this.loadingButton = false
          this.popupModal = false
          this.successAlert = this.$i18n.t('deleteSuccess')
          this.$router.push('/member')
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getMemberDashboard() {
      const uid = this.uid
      let params = {
        shopObjectId: this.shopObjectId,
        memberObjectId: this.objectId,
      }

      pos({
        method: 'get',
        url: '/api/v1.0/' + uid + '/Receipt/memberdashboard/data',
        params: params,
      })
        .then((res) => {
          let purchase = res.data.data
          this.frequencyPurchase = purchase.frequencyPurchase
          this.lastPurchase = purchase.lastPurchase
          this.grandTotal = purchase.grandTotal
          this.bill = purchase.bill
        })
        .catch((error) => {
          console.log(error)
        })
    },
    toggleDetails(item) {
      const position = this.details.indexOf(item)

      position !== -1
        ? this.details.splice(position, 1)
        : this.details.push(item)
    },
    itemsDetail(index) {
      let dataPurchase = this.lastPurchase[index].receiptItems
      let info = []

      for (let data of dataPurchase) {
        if (!data.deleted_at) {
          let discountTotal = data.discountAmount + data.discountBillPerQty
          info.push({
            pluCode: data.pluCode,
            pluName: data.pluName,
            quantitySKU: data.quantitySKU,
            price: util.convertCurrency(data.price),
            netAmount: util.convertCurrency(data.netAmount),
            discountTotal: util.convertCurrency(discountTotal),
            totalAmount: util.convertCurrency(data.totalAmount),
          })
        }
      }
      return info
    },
    validEmail(email) {
      let EMAILREG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return EMAILREG.test(email)
    },
    validatePhone(phone) {
      const MOBILEREG = /^[0]\d{9}$/
      return MOBILEREG.test(phone)
    },
    exportMemberPdf() {
      const uid = this.uid

      const params = {
        shopObjectId: this.shopObjectId,
        memberObjectId: this.objectId,
      }

      report({
        method: 'get',
        url: '/print/v1.0/' + uid + '/packagelabel/a4',
        params: params,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let url = window.URL.createObjectURL(blob)
          window.open(url)
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.circle {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-color: #acd7c6;
  border-radius: 50%;
}

.circle h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #2f9576;
  font-size: 170px;
}
</style>
